import { addDoc, query, where, getDocs } from "firebase/firestore";
import { movementCollection } from "./firebaseApp";
import { createSlice } from "@reduxjs/toolkit";

const movLibSlice = createSlice({
  name: "movLib",
  initialState: { showModal: false, movementsLibrary: [] },
  reducers: {
    updateMovementLibrary(state, actions) {
      state.movementsLibrary = actions.payload;
    },
  },
});

export const movLibActions = movLibSlice.actions;
export default movLibSlice;

/*
 * updates movement library
 */
export const updateMovLib = (movements) => {
  return async (dispatch) => {
    dispatch(movLibActions.updateMovementLibrary(movements));
  };
};

/*
 * Add movement to library
 */

export const addNewMovement = async (newMov) => {
  const docRef = await addDoc(movementCollection, newMov);
  return docRef;
};

/*
 * check if movement name exist
 */
export const checkNewMovementName = async (name) => {
  const q = query(
    movementCollection,
    where("name_lowercase", "==", name.toLowerCase())
  );
  const querySnapshot = await getDocs(q);
  if (querySnapshot.size > 0) {
    throw new Error("Movement name already exist");
  }
};
