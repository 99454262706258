import { Divider, Typography, Link, Box } from "@mui/joy";
import React from "react";
import { APP_VERSION } from "../constants/AppVersion";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "auto",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className={classes.root}>
      <Divider />
      <Typography
        level={!smallScreen ? "body-md" : "body-xs"}
        align="center"
        sx={{ mt: 1, mb: 1 }}
      >
        {"Copyright © "}
        <Link color="inherit">Lemonade Training Oy</Link>
        {" 2023 - "}
        {new Date().getFullYear()}
        {" | "}
        {` App version: ${APP_VERSION}`}
        {" | "}
        <Link color="inherit" href="/privacy-policy">
          Privacy Policy
        </Link>
        {" | "}
        <Link color="inherit" href="/terms-and-conditions">
          Terms and Conditions
        </Link>
        {" | "}
        {"Made with ❤️ in 🇫🇮"}
      </Typography>
    </Box>
  );
};

export default Footer;
