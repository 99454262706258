import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

/* UI */
import {
  Step,
  StepIndicator,
  Button,
  Box,
  Stepper,
  StepButton,
  Typography,
  Divider,
  Stack,
} from "@mui/joy";
import PostAddRoundedIcon from "@mui/icons-material/PostAddRounded";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";

/* Own Modules */
import { TimelineWeek } from "../../Common/Timeline";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "../../store/notification-slice";

/*
 * ======================================================
 * Timeline Control Component
 * ======================================================
 */
interface Props {
  numberOfWeeks: number | null;
  isItMyProgramme: boolean;
  onNumberOfWeekChanged?: (theWeek: TimelineWeek) => void;
  onWeekSelected?: (theWeek: TimelineWeek) => void;
}

const TimelineControl: React.FC<Props> = ({
  numberOfWeeks,
  isItMyProgramme = false,
  onNumberOfWeekChanged,
  onWeekSelected,
}) => {
  const dispatch = useDispatch();

  // ------- state initialization -------
  const weekUseUseStateInit = () => {
    // if numberOfWeeks is not defined, then return empty array
    if (!numberOfWeeks) {
      const newWeek: TimelineWeek = { number: 1, selected: true };
      if (onWeekSelected) onWeekSelected(newWeek);
      if (onNumberOfWeekChanged) onNumberOfWeekChanged(newWeek);
      return [newWeek];
    }
    // if weeks is defined, then return the weeks
    const tempWeeks: TimelineWeek[] = [];
    for (let i = 1; i <= numberOfWeeks; i++) {
      const newWeek: TimelineWeek = { number: i, selected: false };
      if (i === 1) {
        newWeek.selected = true;
        if (onWeekSelected) onWeekSelected(newWeek);
      }
      tempWeeks.push(newWeek);
    }
    return tempWeeks;
  };

  const [weeks, setWeeks] = useState<TimelineWeek[]>(() =>
    weekUseUseStateInit()
  );
  // ------- helpers -------
  const addNewWeek = () => {
    // if number of weeks is more than 12, then return
    if (weeks.length >= 12) {
      dispatch(
        notifyActions.sendNotification({
          type: NOTIFICATION_TYPE.warning,
          message: "Maximum 12 weeks are allowed",
        })
      );
      return;
    }

    const newWeek: TimelineWeek = { number: weeks.length + 1, selected: false };

    // passing data to onNumberOfWeekChanged
    if (onNumberOfWeekChanged) onNumberOfWeekChanged(newWeek);

    setWeeks((prev) => [...prev, newWeek]);
  };

  const selectedWeek = (week: TimelineWeek) => {
    // passing data to onWeekSelected
    if (onWeekSelected) onWeekSelected(week);

    // update the state
    setWeeks((prev) => {
      return prev.map((w) => {
        if (w.number === week.number) {
          w.selected = true;
        } else {
          w.selected = false;
        }
        return w;
      });
    });
  };

  const removeLastWeek = () => {
    // ----- pre-check
    if (weeks.length === 0 || weeks.length === 1) {
      dispatch(
        notifyActions.sendNotification({
          type: NOTIFICATION_TYPE.warning,
          message: "No weeks to remove",
        })
      );
      return;
    }
    // --------------

    if (onNumberOfWeekChanged) {
      // weeks.length - 1 ==> is the week will be removed
      // weeks.length - 2 ==> is the last week that is present
      const lastWeek = weeks[weeks.length - 2];
      onNumberOfWeekChanged(lastWeek);
    }

    setWeeks((prev) => prev.slice(0, -1));
  };

  /* ====================[UI Return]===================== */
  return (
    <>
      <Typography level="title-md">Timeline In Weeks</Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Stepper sx={{ flex: 1 }}>
          {weeks.length > 0 &&
            weeks.map((week, index) => {
              return (
                <Step
                  key={index}
                  indicator={
                    <StepIndicator
                      variant={week.selected === true ? "soft" : "outlined"}
                      color={week.selected === true ? "primary" : "neutral"}
                    >
                      {`${index + 1}`}
                    </StepIndicator>
                  }
                >
                  <StepButton
                    onClick={() => {
                      selectedWeek(week);
                    }}
                  ></StepButton>
                </Step>
              );
            })}
        </Stepper>
        {isItMyProgramme && (
          <Stack direction={"row"} spacing={2} marginLeft={2}>
            <Button
              variant="outlined"
              onClick={addNewWeek}
              sx={{ marginLeft: 5 }}
              startDecorator={<PostAddRoundedIcon />}
            >
              Week
            </Button>
            <Button
              variant="solid"
              color="danger"
              onClick={removeLastWeek}
              startDecorator={<EventBusyRoundedIcon />}
            >
              Week
            </Button>
          </Stack>
        )}
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
    </>
  );
};

export default TimelineControl;
