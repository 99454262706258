import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
/* Material UI */
import { AppBar, Toolbar, Menu, MenuItem } from "@mui/material";
import { Button, Box, Divider, Typography, Stack, IconButton } from "@mui/joy";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import CartBadgeButton from "../components/Cart/CartBadgeButton";
import UserAvatarAndPopoverMenu from "./UserAvatarAndPopoverMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
/* Authentication imports */
import AuthContext from "../store/auth-context";
/* Routing */
import { useNavigate, useLocation } from "react-router-dom";

/* ------- Pages */
const pages = ["Coaches", "Lemonade Signature"];

/* Header Component */
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isUserLoggedIn = useSelector((state) => state.user.isLoggedIn);

  /* Nav Menu */
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  /* button and link Handlers */
  const goToPage = (pageName) => {
    const pageToGo = pageName.replace(/\s+/g, "-"); // replace spaces with hyphens
    navigate(pageToGo, { state: { from: location.pathname } });
  };

  const onClickOnSignup = () => {
    navigate("/register", { state: { from: location.pathname } });
  };

  /* sign out */
  const signOutTheUser = async () => {
    await authCtx.userLogOut();
    goToPage("/");
  };

  return (
    <AppBar
      position={"fixed"}
      color={"default"}
      elevation={0}
      sx={{
        m: 2,
        mr: { xs: 1.1, md: 4.5 },
        zIndex: (theme) => theme.zIndex.drawer + 1,
        borderRadius: 4,
        opacity: 0.9,
        width: "95%",
      }}
    >
      <Toolbar>
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: "black" }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => goToPage(`/${page}`)}>
                    <Typography sx={{ textAlign: "center" }}>{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              onClick={() => goToPage("/")}
              sx={{
                cursor: "pointer",
                fontFamily: "Oswald",
                color: "black",
                fontSize: smallScreen ? 17 : 20,
              }}
            >
              LEMONADE TRAINING™
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => goToPage(`/${page}`)}
                color="transparent"
                sx={{ color: "black" }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Stack direction={"row"} spacing={2}>
            {isUserLoggedIn && <CartBadgeButton />}
            {isUserLoggedIn && (
              <Divider sx={{ color: "white" }} orientation="vertical" />
            )}
            {!isUserLoggedIn && (
              <Stack direction={"row"} spacing={2}>
                <Button
                  color="primary"
                  onClick={onClickOnSignup}
                  size={smallScreen ? "sm" : "md"}
                >
                  Sign Up
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => goToPage("/login")}
                  endDecorator={smallScreen ? <></> : <ChevronRightIcon />}
                  size={smallScreen ? "sm" : "md"}
                >
                  Sign In
                </Button>
              </Stack>
            )}
            {isUserLoggedIn && (
              <UserAvatarAndPopoverMenu onLogout={signOutTheUser} />
            )}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
