/*
 * Lemonade Training Oy copy rights all right reserved.
 * Workout Type Definition
 */

/* -------------- [ Enums and Unions ] ------------------ */
export type WorkoutCategory =
  | "Strength / Weightlifting "
  | "Gymnastics / Skill / Accessory"
  | "Metcon / EMOM"
  | "Monostructural / Cardio"
  | "Test Workouts";

export const WORKOUT_CATEGORIES: WorkoutCategory[] = [
  "Strength / Weightlifting ",
  "Gymnastics / Skill / Accessory",
  "Metcon / EMOM",
  "Monostructural / Cardio",
  "Test Workouts",
];

export type MeasureType = "Meter" | "Cal" | "Kg" | "Time";

export const MEASURE_TYPES: MeasureType[] = ["Meter", "Cal", "Kg", "Time"];

// export type MovementType = "reps" | "time" | "distance" | "none";
export type MovementCategory =
  | "Squats"
  | "Weightlifting"
  | "Gymnastics"
  | "Conditioning"
  | "Accessory";

export const MOVEMENT_CATEGORIES: MovementCategory[] = [
  "Squats",
  "Weightlifting",
  "Gymnastics",
  "Conditioning",
  "Accessory",
];

/* -------------- [ type definitions ] ------------------ */
export type MovementLibObj = {
  name: string;
  name_lowercase: string;
  type?: MovementCategory | null;
  measureType?: MeasureType | null;
  ownerUID?: string;
};

export type Movement = {
  id?: number;
  movObj?: MovementLibObj | null;
  percent?: number;
};

export type WorkoutData = {
  title: string;
  description: string;
  date?: string;
  tags: string[];
  youTubeIDs: string[];
  movements?: Movement[];
  week?: number;
  day?: number | null;
};

export type Workout = {
  category?: WorkoutCategory;
  // -----------------------
  UID?: string;
  CoachUID: string;
  programmingUID: string;
  workoutData: WorkoutData;
};

/* -------------- [ Workout Data Init ] ------------ */
export const WORKOUT_DATA_INIT: WorkoutData = {
  title: "",
  description: "",
  date: "",
  tags: [],
  youTubeIDs: [],
  movements: [],
};
