import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Box, Button, Stack, Divider, Autocomplete, Input } from "@mui/joy";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

/* Own Modules */
import WorkingWeightChip from "./WorkingWeightChip.js";
import { Movement, MovementLibObj } from "../../Common/Workout.tsx";
import AddNewMovement_Modal from "./AddNewMovement_Modal.tsx";

interface MovementItemProps {
  id: number;
  movementData: Movement;
  onGetMovementData?: (data: Movement) => void;
  onRemove: (id: number) => void;
  editMode: boolean;
}

const MovementItem: React.FC<MovementItemProps> = ({
  id,
  movementData,
  onGetMovementData,
  onRemove,
  editMode,
}) => {
  const dispatch = useDispatch();
  const access = useSelector((state: any) => state.user.claims); // Replace 'any' with the appropriate type
  const hasAdminRights = access.admin || access.lemonadeAdmin;
  const hasCoachRights = access.coach || access.admin || access.lemonadeAdmin;
  const movLib = useSelector((state: any) => state.movLib.movementsLibrary); // Replace 'any' with the appropriate type
  const [percent, setPercent] = useState<number>(movementData.percent || 0);
  const [showAddMovementModal, setShowAddMovementModal] = useState(false);

  /*auto complete */
  const [movObj, setMovObj] = useState<MovementLibObj>(
    movementData.movObj as MovementLibObj
  ); // Replace 'any' with the appropriate type
  const [movInputValue, setMovInputValue] = useState<string>("");

  useEffect(() => {
    const movementData: Movement = {
      id: id,
      movObj: movObj,
      percent: percent,
    };

    if (onGetMovementData) {
      onGetMovementData(movementData);
    }
  }, [movObj, percent]);

  /* ---- Handlers ---- */
  const handlePercentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPercent(Number(event.target.value));
  };

  const onMovChanged = (event: any, newValue: any) => {
    // Replace 'any' with the appropriate types
    setMovObj(newValue);
  };

  const onRemoveMovementHandler = () => {
    onRemove(id);
  };

  return (
    <>
      {showAddMovementModal && (
        <AddNewMovement_Modal
          open={showAddMovementModal}
          onClose={() => {
            setShowAddMovementModal(false);
          }}
        />
      )}
      <Stack
        display="flex"
        flexGrow
        direction="row"
        alignItems="flex-end"
        spacing={1}
        sx={{ mb: 1 }}
      >
        {editMode && (
          <IconButton
            aria-label="Remove MovementItem"
            size="small"
            onClick={onRemoveMovementHandler}
            color="error"
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        )}
        <Autocomplete
          sx={{ width: 220 }}
          value={movObj}
          disableClearable={!editMode}
          disabled={!editMode}
          onChange={onMovChanged}
          inputValue={movInputValue}
          onInputChange={(event, newInputValue) => {
            setMovInputValue(newInputValue);
          }}
          id="Movement Item"
          options={[...movLib].sort((a, b) => b.type.localeCompare(a.type))}
          groupBy={(option) => option.type}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          noOptionsText={
            hasCoachRights && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Movement not available?
                <Button
                  variant="outlined"
                  color="primary"
                  onMouseDown={() => {
                    setShowAddMovementModal(true);
                  }}
                >
                  Add
                </Button>
              </Box>
            )
          }
          autoHighlight
        />
        <Divider orientation="vertical" />
        <TextField
          label="of 1RM"
          size="small"
          type="number"
          variant="standard"
          value={percent}
          onChange={handlePercentChange}
          InputProps={{
            readOnly: !editMode,
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
            inputProps: {
              min: 0,
            },
          }}
          sx={{ width: 65 }}
        />
        <WorkingWeightChip movID={movObj && movObj.id} percentage={percent} />
      </Stack>
    </>
  );
};

export default MovementItem;
