import React, { useState, useEffect } from "react";
import {
  Link,
  Typography,
  Button,
  Stack,
  Card,
  CardCover,
  CardContent,
} from "@mui/joy";
import { useTheme } from "@mui/material/styles";
import { Divider, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { motion, useAnimation } from "framer-motion";
import { makeStyles } from "@mui/styles";

/* Routing */
import { useNavigate, useLocation } from "react-router-dom";

/* ------- Assets ------ */
import HERO_IMAGE from "../assets/BACKGROUND_LANDINGPAGE.jpg";
import LemonadeTrainingText from "../assets/LOGO_trademark.PNG";
import LemonadeCoachPng from "../assets/LOGO_COACH.png";

/* ------- Animations ------ */
const picAnimationVariants = {
  loaded: { opacity: 1, scale: 1 },
};

/* ------- stylig ---- */

const useStyles = makeStyles((theme) => ({
  fullBackground: {
    backgroundImage: `url(${HERO_IMAGE})`,
    backgroundSize: "cover",
    backgroundPosition: "50% 28%",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  hereLogo: {
    width: 400,
    height: 150,
    [theme.breakpoints.down("sm")]: {
      width: 250,
      height: 100,
    },
  },
  pic: {
    objectFit: "cover",
    overflow: "hidden",
    borderRadius: 15,
    width: "90%",
    height: "400px",
  },
}));

/*
 * ======================================================
 * Home Page
 * ======================================================
 */
const HomePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles();
  const animationControl = useAnimation();

  /* ----- screen size ----- */
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  /* ----- Animations ----- */
  const imageLoaded = () => {
    animationControl.start("loaded");
  };

  /* ====================[UI Return]===================== */
  return (
    <div className={classes.fullBackground}>
      <Toolbar />
      <Stack
        direction={smallScreen ? "column" : "row"}
        sx={{
          alignItems: "center",
        }}
        spacing={5}
      >
        <motion.div
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.3 },
          }}
        >
          <Card
            variant="plain"
            sx={{
              width: smallScreen ? 320 : 450,
              height: smallScreen ? 110 : 160,
              flexGrow: 1,
              backgroundColor: "transparent",
              borderWidth: 0,
              borderRadius: 0,
              "&:hover": {
                boxShadow: "lg",
              },
            }}
          >
            <CardCover>
              <motion.img
                src={LemonadeTrainingText}
                alt="Lemonade Training Oy"
                initial={{ opacity: 0 }}
                transition={{ duration: 0.6 }}
                animate={animationControl}
                variants={picAnimationVariants}
                onLoad={imageLoaded}
                className={classes.hereLogo}
              />
            </CardCover>
            <CardContent>
              <Typography
                level="body-lg"
                textColor="#fff"
                sx={{ fontWeight: "lg", mt: { xs: 12, sm: 18 } }}
              >
                <Link
                  overlay
                  underline="none"
                  href="/Lemonade-Signature"
                  sx={{ color: "white" }}
                ></Link>
              </Typography>
            </CardContent>
          </Card>
        </motion.div>
        <motion.div
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.3 },
          }}
        >
          <Card
            sx={{
              width: smallScreen ? 250 : 300,
              height: smallScreen ? 250 : 300,
              flexGrow: 1,
              backgroundColor: "transparent",
              borderWidth: 0,
              "&:hover": {
                boxShadow: "lg",
              },
            }}
          >
            <CardCover>
              <motion.img
                src={LemonadeCoachPng}
                alt="Lemonade Training Oy"
                initial={{ opacity: 0 }}
                transition={{ duration: 0.6 }}
                animate={animationControl}
                variants={picAnimationVariants}
                onLoad={imageLoaded}
                className={classes.hereLogo}
              />
            </CardCover>
            <CardContent>
              <Typography
                level="body-lg"
                textColor="#fff"
                sx={{ fontWeight: "lg", mt: { xs: 12, sm: 18 } }}
              >
                <Link
                  overlay
                  underline="none"
                  href="/coaches"
                  sx={{ color: "white" }}
                ></Link>
              </Typography>
            </CardContent>
          </Card>
        </motion.div>
      </Stack>
    </div>
  );
};

export default HomePage;
